import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import {
  navigate,
  getDefaultProps,
  addApolloState,
  initializeApollo,
} from 'helpers';
import { Modal } from 'layouts';
import { useNavigation } from 'components/util/navigation-context';
import Login from 'components/organisms/login';
import ForgottenPassword from 'components/organisms/forgotten-password';
import { useT } from 'react-polyglot-hooks';
import { GetServerSidePropsContext } from 'next';

type LoginPageProps = {
  authenticated?: boolean;
};

const LoginPage = ({ authenticated }: LoginPageProps) => {
  const { setFooter } = useNavigation();

  useEffect(() => {
    setFooter('NONE');
  }, []);

  const [forgetPasswordView, setForgetPasswordView] = useState(false);
  const t = useT();
  if (authenticated) {
    navigate('/lounge');
    return null;
  }

  const title = t('page.title.login');
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          property="og:title"
          content={(title as unknown) as string}
          key="title"
        />
      </Head>
      <Modal modalType="default" isOpen displayBackButton={false} blur>
        {forgetPasswordView ? (
          <ForgottenPassword setForgetPasswordView={setForgetPasswordView} />
        ) : (
          <Login setForgetPasswordView={setForgetPasswordView} />
        )}
      </Modal>
    </>
  );
};

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const apolloClient = initializeApollo(null, ctx);

  const defaultProps = await getDefaultProps(apolloClient, ctx);

  if (defaultProps.authenticated) {
    ctx.res.writeHead(302, { Location: '/lounge' });
    ctx.res.end();
    return { props: {} };
  }

  return addApolloState(apolloClient, {
    props: { ...defaultProps },
  });
};

export default LoginPage;
