import React, { useState } from 'react';
import { navigate } from 'helpers';
import { T, useT } from 'react-polyglot-hooks';
import { useForm } from 'react-hook-form';
import Divider from 'components/atoms/divider';
import LoadingSpinner from 'components/atoms/loading-spinner';
import TextInput from 'components/atoms/text-input';
import {
  fireButtonAnalytics,
  CLICK_BUTTON,
  LOGIN_FORGOTTEN_PASSWORD,
} from 'analytics';
import { usePasswordResetMutation } from 'graphpl/core';
import { AvailableIcons, Iconography } from 'components/styles';
import {
  FormWrapper,
  StyledPill,
  BackButtonWrapper,
  ForgetPasswordMessage,
  PillWrapper,
  ErrorMessage,
} from './forgotten-password.styles';

type ForgottenPasswordProps = {
  setForgetPasswordView: React.Dispatch<React.SetStateAction<boolean>>;
  storybookLoading?: boolean;
  storybookErrorMessage?: string;
  storybookSubmitted?: boolean;
  storybookMessage?: string;
};

const ForgottenPassword = ({
  setForgetPasswordView,
  storybookLoading,
  storybookErrorMessage,
  storybookSubmitted,
  storybookMessage,
}: ForgottenPasswordProps) => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<
    string | undefined | null
  >(undefined);
  const t = useT();
  const resetPasswordInvalidEmailPhrase = t('resetPasswordInvalidEmail');

  const [
    doPasswordReset,
    { loading: resetLoading, error: resetError },
  ] = usePasswordResetMutation({
    onCompleted: ({ requestPasswordResetEmail } = {}) => {
      setResponseMessage(requestPasswordResetEmail);
      fireButtonAnalytics({
        event: CLICK_BUTTON,
        source: LOGIN_FORGOTTEN_PASSWORD,
      });
      setFormSubmitted(true);
    },
    onError: () => {},
  });
  const { handleSubmit, register, errors: formErrors } = useForm<{
    email: string;
  }>();
  const formID = 'reset-password';
  const errorMessage = storybookErrorMessage || resetError?.message;
  const loading = storybookLoading || resetLoading;
  const submitted = storybookSubmitted || formSubmitted;
  const message = storybookMessage || responseMessage;

  return (
    <>
      <BackButtonWrapper onClick={() => setForgetPasswordView(false)}>
        <Iconography
          name={AvailableIcons.BACK_ARROW_CHEVRON}
          data-testid="forget-password-back-btn"
        />
        <T phrase="forgotPassword" />
      </BackButtonWrapper>
      <Divider />
      <FormWrapper
        data-testid="password-reset-form"
        onSubmit={handleSubmit((variables) => doPasswordReset({ variables }))}
      >
        {!submitted ? (
          <ForgetPasswordMessage>
            <T phrase="enterEmail" />
          </ForgetPasswordMessage>
        ) : (
          message
        )}
        {!submitted ? (
          <>
            <TextInput
              id={`${formID}-email`}
              name="email"
              type="email"
              label={<T phrase="emailAddress" />}
              ref={register({
                required: 'Please enter your email',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
                  message: (resetPasswordInvalidEmailPhrase as unknown) as string,
                },
              })}
              error={Boolean(formErrors.email)}
              message={formErrors.email?.message}
              disabled={loading || submitted}
            />
            <PillWrapper>
              <ErrorMessage>{errorMessage}</ErrorMessage>
              <StyledPill
                data-testid="reset-password-btn"
                type="submit"
                size="large"
                disabled={loading}
              >
                {loading ? (
                  <LoadingSpinner color="white" />
                ) : (
                  <T phrase="resetPassword" />
                )}
              </StyledPill>
            </PillWrapper>
          </>
        ) : (
          <PillWrapper>
            <StyledPill
              data-testid="back-to-lounge-btn"
              size="large"
              onClick={() => navigate('/lounge')}
            >
              <T phrase="backToLogin" />
            </StyledPill>
          </PillWrapper>
        )}
      </FormWrapper>
    </>
  );
};

export default ForgottenPassword;
