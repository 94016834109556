import React, { useState } from 'react';
import {
  fireButtonAnalytics,
  CLICK_BUTTON,
  LOGIN_ATTEMPT,
  LOGIN_COMPLETE,
} from 'analytics';
import { setAuthToken, navigate, fetchUserIdFromAuthToken } from 'helpers';
import { useForm } from 'react-hook-form';
import { T } from 'react-polyglot-hooks';
import LoadingSpinner from 'components/atoms/loading-spinner';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import Divider from 'components/atoms/divider';
import {
  useLoginMutation,
  useTriggerUserTwoFactorAuthMutation,
  useVerifyUserTwoFactorAuthMutation,
} from 'graphpl/core';
import TextInput from 'components/atoms/text-input';
import { useGlobal } from 'components/util/global-context';
import {
  FormWrapper,
  SignupWrapper,
  StyledPill,
  LoginHeader,
  LoginHeaderWrapper,
  ForgotPassword,
  ButtonsWrapper,
  StyledLink,
  ErrorMessage,
  InfoMessage,
} from './login.styles';

const dodgyHardCodedUserIdListToSkip2FA = [
  '5987366066604901',
  '4281585250649332',
  '6358377431138544',
  '4297982365916136',
  '92300814',
  '2165385818975995',
  '6083148472764931',
  '31717593',
  '5285598761521875',
  '268071052270073',
  '1422319162927322',
];

type LoginPanelProps = {
  setForgetPasswordView: (value: boolean) => void;
  storybookErrorMessage?: string;
  storybookLoading?: boolean;
};

const LoginPanel = ({
  setForgetPasswordView,
  storybookErrorMessage,
  storybookLoading,
}: LoginPanelProps) => {
  const { featureFlag } = useGlobal();
  const twoFAEnabled = featureFlag('two-factor-auth').enabled;

  const router = useRouter();
  const redirectQuery = `${router.query?.redirect || ''}`;

  const [twoFactorRender, setTwoFactorRender] = useState(false);
  const [loginToken, setLoginToken] = useState<string | null>(null);
  const [loginVariables, setLoginVariables] = useState<{
    email?: null | string;
    password?: null | string;
    twoFactorCode?: null | string;
  }>({
    email: null,
    password: null,
    twoFactorCode: null,
  });

  const updateLoginVariables = (args: {
    email: string | null;
    password: string | null;
    twoFactorCode: string | null;
  }) => {
    setLoginVariables({
      ...loginVariables,
      ...args,
    });
  };

  const [
    triggerTwoFactorAuth,
    { loading: twoFactorLoading, error: twoFactorError },
  ] = useTriggerUserTwoFactorAuthMutation();

  const [
    doLogin,
    { loading: loginLoading, error: loginError },
  ] = useLoginMutation({
    onCompleted: ({ login } = {}) => {
      fireButtonAnalytics({
        event: CLICK_BUTTON,
        source: LOGIN_ATTEMPT,
      });
      if (login) {
        const userId = fetchUserIdFromAuthToken(login);

        const twoFAIsNotRequired =
          !twoFAEnabled ||
          dodgyHardCodedUserIdListToSkip2FA.includes(userId?.toString());

        if (twoFAIsNotRequired) {
          // redirect query to be added into the redirect if exist.
          setAuthToken(login);
          fireButtonAnalytics({
            event: CLICK_BUTTON,
            source: LOGIN_COMPLETE,
          });
          navigate(redirectQuery || '/lounge'); // Hard redirect to refresh auth status
          return;
        }

        // Got login token - trigger 2FA flow
        setLoginToken(login);
        triggerTwoFactorAuth({
          variables: {
            email: loginVariables.email,
          },
        });
        setTwoFactorRender(true);
      }
    },
  });

  const [
    verifyTwoFactorAuth,
    { loading: verifyLoading, error: verifyError },
  ] = useVerifyUserTwoFactorAuthMutation({
    onCompleted: ({ verifyUserTwoFactorAuth } = {}) => {
      if (verifyUserTwoFactorAuth) {
        // redirect query to be added into the redirect if exist.
        setAuthToken(loginToken || '');
        fireButtonAnalytics({
          event: CLICK_BUTTON,
          source: LOGIN_COMPLETE,
        });
        navigate(redirectQuery || '/lounge'); // Hard redirect to refresh auth status
      }
    },
  });

  const TwoFactorAuthConfirmView = () => {
    const formID = 'twoFactorAuthConfirm';
    const { handleSubmit, register, errors: formErrors } = useForm();

    let errorMessage;
    if (storybookErrorMessage) {
      errorMessage = storybookErrorMessage;
    } else if (loginError?.message) {
      errorMessage = loginError.message;
    } else if (verifyError?.message) {
      errorMessage = verifyError.message;
    }

    return (
      <FormWrapper
        id={formID}
        data-testid="two-factor-form"
        onSubmit={handleSubmit(async (variables) => {
          // const token = await executeRecaptcha('login_page');

          const mutationParams = {
            email: loginVariables.email,
            code: variables.twoFactorCode,
          };
          verifyTwoFactorAuth({ variables: mutationParams });
        })}
        autoComplete="on"
      >
        <InfoMessage>
          We have sent a code to the email used to log in. Please check your
          emails and enter the code below.
        </InfoMessage>
        <TextInput
          id={`${formID}-twoFactorCode`}
          name="twoFactorCode"
          type="text"
          ref={register({
            required: 'Please enter your two factor code',
          })}
          label={<T phrase="twoFactor" />}
          error={formErrors.twoFactor}
          autoComplete="email"
          message={formErrors.email?.message}
          disabled={verifyLoading}
        />
        <ButtonsWrapper>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <StyledPill
            data-testid="manual-confirm-button"
            type="submit"
            size="large"
            disabled={verifyLoading}
          >
            {verifyLoading ? (
              <LoadingSpinner color="white" />
            ) : (
              <T data-testid="login-pill" phrase="submitTwoFactor" />
            )}
          </StyledPill>
        </ButtonsWrapper>
      </FormWrapper>
    );
  };

  const LoginForm = () => {
    const formID = 'login';

    const { handleSubmit, register, errors: formErrors } = useForm<{
      email: string;
      password: string;
    }>();

    let errorMessage;

    if (storybookErrorMessage) {
      errorMessage = storybookErrorMessage;
    } else if (loginError?.message) {
      errorMessage = loginError.message;
    } else if (twoFactorError?.message) {
      errorMessage = twoFactorError.message;
    }

    const loading = storybookLoading || twoFactorLoading || loginLoading;
    return (
      <FormWrapper
        id={formID}
        data-testid="login-form"
        onSubmit={handleSubmit(async (variables) => {
          const mutationParams = { ...variables };
          updateLoginVariables({
            email: variables.email,
            password: variables.password,
            twoFactorCode: null,
          });
          doLogin({ variables: mutationParams });
        })}
        autoComplete="on"
      >
        <TextInput
          id={`${formID}-email`}
          name="email"
          type="email"
          label={<T phrase="emailAddress" />}
          ref={register({
            required: 'Please enter your email',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/i,
              message: 'Invalid email address',
            },
          })}
          error={Boolean(formErrors.email)}
          autoComplete="email"
          message={formErrors.email?.message}
          disabled={loading}
        />
        <TextInput
          id={`${formID}-password`}
          name="password"
          type="password"
          autoComplete="current-password"
          label={<T phrase="password" />}
          ref={register({
            required: 'Please enter your password',
            minLength: {
              value: 7,
              message: 'Password should be at least 7 characters long',
            },
            maxLength: {
              value: 256,
              message:
                'Password should be less than 256 characters long. Please reset your password if it is over that length.',
            },
          })}
          error={Boolean(formErrors.password)}
          message={formErrors.password?.message}
          disabled={loading}
        />
        <ButtonsWrapper>
          <ErrorMessage>{errorMessage}</ErrorMessage>
          <ForgotPassword
            data-testid="forget-password-btn"
            onClick={() => setForgetPasswordView(true)}
            error={Boolean(errorMessage)}
          >
            <T phrase="forgotPassword" />
          </ForgotPassword>
          <StyledPill
            data-testid="manual-login-button"
            type="submit"
            size="large"
            disabled={loading}
          >
            {loading ? (
              <LoadingSpinner color="white" />
            ) : (
              <T data-testid="login-pill" phrase="login" />
            )}
          </StyledPill>
        </ButtonsWrapper>
      </FormWrapper>
    );
  };
  const searchParams = useSearchParams() || { toString: () => 'mock toString' };

  const search = `?${searchParams.toString()}`;

  return (
    <>
      <LoginHeaderWrapper>
        <LoginHeader>
          <T phrase="loginToPl" />
        </LoginHeader>
      </LoginHeaderWrapper>
      <Divider />
      {twoFactorRender ? <TwoFactorAuthConfirmView /> : <LoginForm />}

      <Divider />
      <SignupWrapper>
        <T phrase="dontHaveAccount" />
        <StyledLink href={`/signup${search}`}>
          <T phrase="signup" />
        </StyledLink>
      </SignupWrapper>
    </>
  );
};

export default LoginPanel;
